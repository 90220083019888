<template>
  <div class="box">
    <div class="banerText">
      <div @click="prev" class="homeText">{{ i18n.User.home }}</div>
      <div class="icon homeText">></div>
      <div @click="toUser" class="homeText">{{ i18n.User.personal }}</div>
      <div class="icon">></div>
      <div class="userInfoText">{{ i18n.User.changePassword }}</div>
    </div>
    <div class="content">
      <el-card class="">
        <div class="box-card">
          <el-form ref="form" :rules="rules" :model="form" label-width="80px">
            <el-form-item required :label="i18n.User.Old" prop="oldPassword">
              <el-input
                :show-password="true"
                :placeholder="i18n.User.Please1"
                v-model="form.oldPassword"
              ></el-input>
            </el-form-item>
            <el-form-item required :label="i18n.User.New" prop="newPassword">
              <el-input
                :show-password="true"
                :placeholder="i18n.User.Please2"
                v-model="form.newPassword"
              ></el-input>
            </el-form-item>
            <el-form-item
              required
              :label="i18n.User.Enter"
              prop="secondPassword"
            >
              <el-input
                :show-password="true"
                :placeholder="i18n.User.Please2"
                v-model="form.secondPassword"
              ></el-input>
            </el-form-item>
            <el-button
              class="btn"
              @click="submit"
              :loading="loading"
              :disabled="disabled"
              :class="!disabled ? 'actived' : ' '"
              :type="type"
              round
              >完成</el-button
            >
          </el-form>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { resetPwd, apiLoginout } from '@/api/api.js'
import i18n from '@/i18n/i18n'

export default {
  components: {},
  data() {
    var validatePass = (rule, value, callback) => {
      if (!value) {
        callback(
          new Error(
            i18n.locale === 'ch'
              ? '请输入新密码'
              : 'Please enter a new password'
          )
        )
      } else if (value.toString().length < 6 || value.toString().length > 18) {
        callback(
          new Error(
            i18n.locale === 'ch'
              ? "'密码长度为6 - 18个字符'"
              : 'password length is 6 - 18 characters'
          )
        )
      } else {
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(
          new Error(
            i18n.locale === 'ch'
              ? '请再次输入密码'
              : 'Please enter the password again'
          )
        )
      } else if (value !== this.form.newPassword) {
        callback(
          new Error(
            i18n.locale === 'ch'
              ? '两次输入密码不一致!'
              : 'The two passwords are inconsistent!'
          )
        )
      } else {
        callback()
      }
    }
    return {
      form: {
        oldPassword: '',
        newPassword: '',
        secondPassword: ''
      },
      loading: false,
      rules: {
        oldPassword: [
          {
            required: true,
            message:
              i18n.locale === 'ch'
                ? '请输入旧密码'
                : 'Please enter your old password',
            trigger: 'blur'
          }
        ],
        newPassword: [
          {
            required: true,
            message:
              i18n.locale === 'ch'
                ? '请输入新密码'
                : 'Please enter a new password',
            trigger: 'blur'
          },
          { required: true, validator: validatePass, trigger: 'change' }
        ],
        secondPassword: [
          {
            required: true,
            message:
              i18n.locale === 'ch'
                ? '请输入新密码'
                : 'Please enter a new password',
            trigger: 'blur'
          },
          { required: true, validator: validatePass2, trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    type() {
      if (this.disabled) {
        return 'info'
      } else {
        return 'primary'
      }
    },
    i18n() {
      return this.$t('messages')
    },
    disabled() {
      if (
        this.form.oldPassword &&
        this.form.newPassword &&
        this.form.secondPassword
      ) {
        return false
      } else {
        return true
      }
    }
  },

  methods: {
    prev() {
      this.$router.push({ name: 'home' })
    },
    toUser() {
      this.$router.push({ name: 'userInfo' })
    },
    loginOut() {
      apiLoginout().then((res) => {
        window.location.href = './'
      })
    },
    submit() {
      resetPwd({
        pwd_old: this.form.oldPassword,
        pwd_new: this.form.newPassword,
        pwd_new2: this.form.secondPassword
      })
        .then((res) => {
          if (res.code != 200) {
            return
          }
          if (res.data === 1) {
            this.$layer.msg(
              i18n.locale === 'ch'
                ? "<div class='success-icon'></div><div>修改成功</div>"
                : "<div class='success-icon'></div><div>Modified successfully</div>"
            )
            this.loginOut()
            return
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  .banerText {
    margin-top: 26px;
    display: flex;
    font-size: 14px;

    font-weight: 400;
    line-height: 17px;
    color: #1f1f40;
    .homeText {
      color: #9c9c9c;
      font-size: 14px;
    }
    .icon {
      margin-left: 19px;
      margin-right: 19px;
    }
  }
  .content {
    margin-top: 19px;
    .box-card {
      width: 330px;
      margin: 0 auto;
      .btn {
        width: 223px;
        height: 42px;
        margin-left: 55px;
        margin-top: 69px;
        margin-bottom: 52px;
      }
      /deep/.el-input__inner {
        border-radius: 0;
        border: none;
        border-bottom: 1px solid #dcdcdc;
      }
      .actived {
        background-color: #3b86ff;
      }
    }
  }
}
</style>
